import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  class: "footer XhorizontalCenter"
};
const _hoisted_2 = {
  class: "footer_body flexX"
};
const _hoisted_3 = {
  class: "footer_body_boxOne"
};
const _hoisted_4 = {
  class: "flexX boxs"
};
const _hoisted_5 = ["href"];
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "footer_body_boxTwo"
};
const _hoisted_8 = {
  class: "footer_body_boxTwo_box flexX"
};
const _hoisted_9 = ["onClick"];
const _hoisted_10 = {
  class: "footer_number XhorizontalCenter"
};
const _hoisted_11 = {
  class: "flexX footer_number_body"
};
const _hoisted_12 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString(_ctx.store_.systemMode("connectNet")), 1), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.barLists, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "footer_body_boxOne_imgBox flexX",
      key: index
    }, [_createElementVNode("a", {
      target: "_blank",
      href: item.url
    }, [_createElementVNode("img", {
      src: item.logo
    }, null, 8, _hoisted_6)], 8, _hoisted_5)]);
  }), 128))])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", null, _toDisplayString(_ctx.links.name), 1), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", {
    class: "footer_body_boxTwo_box_1 flex1",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.lows())
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links.content_list, (items, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "footer_body_boxTwo_box_1_text",
      key: index,
      onClick: $event => _ctx.jump(items, index)
    }, _toDisplayString(items.title), 9, _hoisted_9);
  }), 128))])])])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.number, (item, index) => {
    return _openBlock(), _createElementBlock("a", {
      href: item.jump_link,
      style: _normalizeStyle(index != 2 ? 'pointer-events: none;' : 'cursor: pointer;'),
      target: "_blank",
      key: index
    }, _toDisplayString(item.title), 13, _hoisted_12);
  }), 128))])])]);
}