import { defineComponent } from "vue";
import { getLinks, getPageMessage } from "@/api";
import { useAppStore } from "@/store";
const store = useAppStore();
export default defineComponent({
  data() {
    return {
      store_: store,
      barLists: [],
      links: [],
      number: []
    };
  },
  async created() {
    try {
      const src = await getLinks({
        cid: 9,
        gid: 1
      });
      this.barLists = src.data[0].links;
    } catch (e) {
      console.error(e);
    }
    const links = await getPageMessage(store.getPageID("links"));
    this.links = links.data;
    // const number = await getPageMessage(store.getPageID('recordNumber'))
    // this.number = (number as any).data.content_list
    this.number = [{
      title: "海南智渔可持续科技发展研究中心",
      jump_link: ""
    }, {
      title: `版权所有 2012-${new Date().getFullYear()} © All Rights resvered`,
      jump_link: ""
    }, {
      title: "琼ICP备16000991号-6",
      jump_link: "https://beian.miit.gov.cn/#/Integrated/index"
    }];
  },
  methods: {
    lows() {
      this.$emit("lows", "toTop");
    },
    jump(item, index) {
      console.debug(item);
      let linkMsg = item.jump_link.split("_");
      let cc = Math.round(Math.random() * 10000);
      let dataType0 = {
        // query: {
        // 	//这里的数据的来自后台对应页面的栏目链接上，由'_'分割，对应数据的排列顺序是  页面ID 页面名称 模板类型ID 广告控制 广告ID
        // 	pathId: linkMsg[1], name: linkMsg[2], listType: linkMsg[3], adShow: linkMsg[4], adNum: linkMsg[5]
        // }
        query: {
          pathId: linkMsg[1],
          ppid: index
        }
      };
      store.setLekData("");
      store.setRouter(dataType0);
      store.setTopBarIndex(index);
      if (item.is_blank == 1) {
        const url = this.$router.resolve({
          path: "/richText",
          query: dataType0.query
        });
        window.open(url.href);
      } else {
        this.$router.push({
          path: "/richText",
          query: dataType0.query
        });
      }
    }
  }
});